import React, { useEffect, useState } from 'react';
import { Calendar, MapPin, Users, Home, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import AcontecimientosService from "../services/acontecimientosService.tsx";
import { useAuth0 } from '@auth0/auth0-react';

interface Evento {
  acontecimiento_id: string;
  nombre: string;
  descripcion: string;
  fecha: string;
  ubicacion: {
    nombre: string;
  };
  socios: Array<{ id: string; nombre: string }>;
}

export default function MisEventos({ onBack }: { onBack: () => void }) {
  const { getAccessTokenSilently } = useAuth0();
  const [eventos, setEventos] = useState<Evento[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedSocio = localStorage.getItem('socioSeleccionado');
        if (!storedSocio) {
          throw new Error('Socio no encontrado en el localStorage');
        }

        const socio = JSON.parse(storedSocio);
        const id = socio.socio_id;
        if (!id) {
          throw new Error('ID del socio no encontrado');
        }

        const data = await AcontecimientosService.getAcontecimientosSocios(id, getAccessTokenSilently);
        setEventos(data);
      } catch (error: any) {
        console.error('Error al cargar los eventos:', error.message);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  return (
    <div className="container mx-auto p-4">
      <div className="mb-6">
        <nav className="flex mb-6" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link to="/inicio" className="inline-flex text-sm font-medium text-blue-600 hover:text-blue-800">
                <Home className="w-4 h-4 mr-2" />
                Inicio
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRight className="w-4 h-4 text-gray-400" />
                <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Mis Eventos</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>

      <h1 className="text-3xl font-bold mb-6">Mis Eventos</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {eventos.map((evento) => (
          <div key={evento.acontecimiento_id} className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:scale-105">
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-2">{evento.nombre}</h2>
              <p className="text-gray-600 mb-4">{evento.descripcion}</p>
              <div className="flex items-center text-sm text-gray-500 mb-2">
                <Calendar className="w-4 h-4 mr-2" />
                {(() => {
                  const date = evento.fecha.split('T')[0];
                  const [year, month, day] = date.split('-');
                  return `${day}/${month}/${year}`;
                })()}
              </div>
              <div className="flex items-center text-sm text-gray-500 mb-2">
                <MapPin className="w-4 h-4 mr-2" />
                {evento.ubicacion.nombre}
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <Users className="w-4 h-4 mr-2" />
                {evento.socios.length} participantes
              </div>
            </div>
            <div className="bg-gray-100 px-6 py-4">
              <button className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors">
                Más información
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
