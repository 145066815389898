import React from "react";
import "./styles/App.css";
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "./components/NavBar.tsx";
import Footer from "./components/Footer.tsx";
import AppRoutes from "./routes/AppRoutes.jsx";
import "./styles/tailwind.css";
import Titulo from "./components/head/Titulo.tsx";
function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="min-h-screen">
        <Titulo texto={"Intercam"} />
        <Navbar />
        <AppRoutes />
      </div>
      <Footer />
    </div>
  );
}

export default App;
