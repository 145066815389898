import React, { useEffect, useState } from "react";
import {
  CreditCard,
  Calendar,
  Shield,
  Bell,
  Settings,
  Gift,
} from "lucide-react";
import Loading from "./Loading.tsx";
import { Socio } from "../types/Socio.tsx";

const initialSocio: Socio = {
  socio_mail: "",
  socio_dni: "",
  socio_cuit: "",
  socio_domicilio: "",
  socio_id: "",
  socio_nombre: "",
  socio_tipoEmpresa: "",
  socio_campanias: "",
  socio_acta: 0,
  socio_celular: "",
  socio_condicionFiscal: 0,
  socio_enviarMail: false,
  socio_estado: 0,
  socio_fechaAprobacion: "",
  socio_fechaNacimiento: "",
  socio_firma: "",
  socio_gestion: "",
  socio_habilitacion: "",
  socio_localidad: 0,
  socio_modificado: "",
  socio_motivoBaja: "",
  socio_nacionalidad: "",
  socio_numero: 0,
  socio_otroTelefono: "",
  socio_padrino1: "",
  socio_padrino2: "",
  socio_rubro: 0,
  socio_sector: 0,
  socio_segmento: "",
  socio_tarjetaEntregada: false,
  socio_tarjetaFechaEntrega: null,
  socio_telefono: "",
  socio_tieneCajaSeguridad: false,
  socio_tipoSocio: "",
};

export default function Dashboard() {
  const [socio, setSocio] = useState<Socio>(initialSocio);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    try {
      setLoading(true);
      const storedSocio = localStorage.getItem("socioSeleccionado");
      if (storedSocio) {
        const socioData: Socio = JSON.parse(storedSocio);
        setSocio(socioData);
      }
    } catch (error) {
      throw new Error("no funciona");
    } finally {
      setLoading(false);
    }
  }, []);
  const cards = [
    {
      icon: CreditCard,
      title: "Mis cuotas pagas",
      description: "Revisa el estado de tus cuotas y pagos pendientes.",
      link: "/cuotas",
    },
    {
      icon: Bell,
      title: "Notificaciones",
      description: "Revisa tus notificaciones y mensajes importantes.",
      link: "/notificaciones",
    },
    {
      icon: Calendar,
      title: "Mis eventos",
      description: "Explora los eventos disponibles para ti como socio.",
      link: "/eventos",
    },
    {
      icon: Shield,
      title: "Mi caja de seguridad",
      description: "Accede a tu caja de seguridad personal.",
      link: "/contratos",
    },
    {
      icon: Settings,
      title: "Perfil",
      description: "Ajusta las preferencias de tu cuenta.",
      link: "/perfil",
    },
    {
      icon: Gift,
      title: "Beneficios",
      description: "Obtén descuentos y cupones de envío gratis.",
      link: "/beneficios",
    },
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="min-h-screen bg-gray-100">
        <div className="container mx-auto px-4 py-8 sm:px-6 lg:px-8">
          <h1 className="text-3xl sm:text-4xl font-bold mb-2 text-left">
            Bienvenido, {socio?.socio_nombre}
          </h1>
          <p className="text-gray-600 mb-6 text-left">
            Gestiona tu estado de socio
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {cards.map((card, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center transform transition duration-300 ease-in-out hover:scale-105 h-full"
              >
                <card.icon className="w-12 h-12 mb-4 text-blue-500" />
                <h2 className="text-xl font-semibold mb-2 text-center">
                  {card.title}
                </h2>
                <p className="text-gray-600 mb-4 text-center flex-grow">
                  {card.description}
                </p>
                <a
                  href={card.link}
                  onClick={(e) => {
                    if (card.link === "#") {
                      e.preventDefault();
                    }
                  }}
                  className="text-blue-500 hover:text-blue-700 transition-colors mt-auto inline-flex items-center"
                >
                  Ver detalles
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
