import React from "react";


export default function Loading() {
  return (
    <div className="flex items-center justify-center h-full">
    <div
      className="w-12 h-12 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"
      role="status"
      aria-label="Loading"
    ></div>
  </div>
  )
}