import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ChevronDown, LogOut, Menu, Users } from "lucide-react";
import LogoCamara from "../assets/LogoCamara.png";
import { useNavigate  } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { logout, isAuthenticated, user } = useAuth0();
  console.log(user);
  
  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin } as any);
  };
  const handleSocio = ()=>{
    localStorage.removeItem('socioSeleccionado');
    navigate("/seleccionar-socio");
  }
  // const cleanStorage = () => {
  //   localStorage.clear();
  // };

  return (
    isAuthenticated && (
      <nav className="bg-white shadow-md">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <img
                src={LogoCamara}
                alt="Camara Comercial Bolivar"
                className="w-12 h-12 sm:w-16 sm:h-16 object-contain"
              />
              <span className="hidden md:block ml-2 text-sm sm:text-base lg:text-xl font-bold">
                Cámara Comercial e Industrial de Bolívar
              </span>
            </div>

            <div className="hidden md:flex items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center space-x-2 hover:bg-gray-100 px-3 py-2 rounded-md transition-colors"
                aria-haspopup="true"
                aria-expanded={isOpen}
              >
                <img
                  className="w-8 h-8 rounded-full"
                  src={user?.picture}
                  alt={user?.name}
                />
                <span className="text-sm lg:text-base">{user?.name}</span>
                <ChevronDown
                  className={`w-4 h-4 transition-transform ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              </button>
              {isOpen && (
                <div className="absolute right-0 mt-32 w-48 bg-white rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5">
                  <button
                    onClick={handleSocio}
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                  >
                    <Users className="w-4 h-4 mr-2" />
                    Cambiar Socio
                  </button>
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    Cerrar sesión
                  </button>
                </div>
              )}
            </div>

            <div className="md:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <Menu className="block h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <div className="flex items-center px-3 py-2">
                <img
                  className="w-8 h-8 rounded-full mr-2"
                  src={user?.picture}
                  alt={user?.name}
                />
                <span className="text-base font-medium">{user?.name}</span>
              </div>
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition-colors"
              >
                <LogOut className="w-5 h-5 mr-2" />
                Cerrar sesión
              </button>
            </div>
          </div>
        )}
      </nav>
    )
  );
}
