import axios from 'axios';

const ContratosService = {
  async getContratosSocios(id: number, accessToken: string | null) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/socio/${id}/movimientos-cofre`, 
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const movimientos = response.data.movimientos || [];
      
      const filteredMovimientos = movimientos
        .filter((movimiento: any) => movimiento.MovimientoCuentaCorrienteCofre_comprobanteTipo === 2)
        .map((movimiento: any) => ({
          ...movimiento,
          MovimientoCuentaCorrienteCofre_fechaIngreso: formatFecha(movimiento.MovimientoCuentaCorrienteCofre_fechaIngreso),
        }));

      return filteredMovimientos;
    } catch (error) {
      console.error('Error al obtener los movimientos del socio:', error);
      throw error;
    }
  }
};

// Función para formatear la fecha
function formatFecha(fecha: number): string {
  const fechaStr = fecha.toString();
  const year = fechaStr.slice(0, 4);
  const month = fechaStr.slice(4, 6);
  const day = fechaStr.slice(6, 8);
  return `${day}/${month}/${year}`;
}

export default ContratosService;
