import React, { useEffect, useState } from 'react';
import { Search } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading.tsx';
import socioService from '../services/socioService.tsx';
import { useSocioContext } from '../context/socioContext.tsx';
import { Socio } from '../types/Socio.tsx';
import { useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard.tsx';
import Scheleton from './Scheleton.tsx';
import { SearchResults } from './buscador/SearchResults.tsx';


export default function AdminDashboard() {
  const {socio, setSocio} = useSocioContext();
  const [listaSocios, setListaSocios] = useState<Socio[] | null>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchResults(true);
    setError(null);
    setListaSocios([]);
    if (!searchTerm.trim()) {
      setError('El campo de búsqueda no puede estar vacío.');
      setSearchResults(false);
      return;
    }

    try {
      const data = await socioService.searchSocios(searchTerm, getAccessTokenSilently);
      if (data.length === 0) {
        setError('No se encontraron socios que coincidan con la búsqueda.');
      } else {
        setListaSocios(data);
      }
    } catch (err) {
      setError('No se pudieron cargar los socios. Intenta nuevamente.');
      console.error('Error en la búsqueda:', err);
    } finally {
      setSearchResults(false);
    }
  };


  const handleSelectSocio = async (socioId: string) => {
    try {
      setLoading(true);
      const socioData = await socioService.getSocioById(socioId, getAccessTokenSilently);
      setSocio(socioData);
      socioService.saveSelectedSocioInLocalStorage(socioData);
      navigate('/inicio')
    } catch (err) {
      setError('Error al obtener los datos del socio');
      console.error('Error al seleccionar socio:', err);
    } finally {
      setLoading(false);
    }
    
  };

  return (
    <div className="min-h-screen bg-gray-200 p-8">
    {/* Contenedor principal de la página */}
    <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-md p-6">
      {/* Título principal */}
      <h1 className="text-3xl font-bold text-center text-blue-700 mb-10">
        Cámara Comercial de Bolívar
      </h1>
      
      {/* Sección de métricas rápidas estilo Dashboard
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
        <div className="bg-blue-100 p-4 rounded-lg shadow text-center">
          <p className="text-xl font-semibold text-blue-700">Socios Activos</p>
          <p className="text-4xl mt-2">1250</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow text-center">
          <p className="text-xl font-semibold text-green-700">Socios Nuevos Este Mes</p>
          <p className="text-4xl mt-2">45</p>
        </div>
        <div className="bg-red-100 p-4 rounded-lg shadow text-center">
          <p className="text-xl font-semibold text-red-700">Socios Pendientes</p>
          <p className="text-4xl mt-2">15</p>
        </div>
      </div>
   */}
      {/* Contenedor con el formulario de búsqueda y resultados */}
      <div className="bg-gray-100 p-8 rounded-lg">
        <div className="max-w-5xl mx-auto">
          <form onSubmit={handleSearch} className="mb-6 relative w-full mx-auto">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700" />
            <input
              type="text"
              placeholder="Buscar por nombre de socio..."
              className="w-full h-17 pl-12 pr-6 py-2 border rounded-lg text-lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              type="submit"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-700 text-lg h-12 px-6"
            >
              Buscar
            </button>
          </form>
  
          {loading && <Loading />}
  
          {searchResults && <Scheleton />}
  
          {error && <div className="py-4 text-center text-red-500">{error}</div>}
  
          <div className="bg-white rounded-lg shadow w-full text-left">
            <SearchResults listaSocios={listaSocios} handleSelectSocio={handleSelectSocio} />
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
}


