import React, { useEffect, useState } from "react";
import { CreditCard, Calendar, Shield, Bell, Settings, Gift, Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading.tsx";
import { Socio } from "../types/Socio.tsx";
import { useAuth } from "../context/AuthProvider.tsx";

 
interface DashboardProps {
  socio?: Socio;
  isAdminView?: boolean;
}

const Card = ({
  icon: Icon,
  title,
  description,
  link,
  navigate,
}: {
  icon: React.ComponentType<any>;
  title: string;
  description: string;
  link: string;
  navigate: (path: string) => void;
}) => (
  <div
    className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center transform transition duration-300 ease-in-out hover:scale-105 h-full cursor-pointer"
    onClick={() => navigate(link)}
  >
    <Icon className="w-12 h-12 mb-4 text-blue-500" />
    <h2 className="text-xl font-semibold mb-2 text-center">{title}</h2>
    <p className="text-black-100 mb-4 text-center flex-grow">{description}</p>
    <a
      href={link}
      onClick={(e) => {
        e.preventDefault();
        navigate(link);
      }}
      className="text-blue-500 hover:text-blue-700 transition-colors mt-auto inline-flex items-center"
    >
      Ver detalles
    </a>
  </div>
);

export default function Dashboard({ socio: propSocio }: DashboardProps) {
  const [socio, setSocio] = useState<Socio>();
  const [loading, setLoading] = useState<boolean>(false);
  const { isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const cargarSocio = () => {
      if (propSocio) {
        setSocio(propSocio);
        return;
      }
      const storedSocio = localStorage.getItem("socioSeleccionado");
      if (storedSocio) {
        try {
          const socioParsed = JSON.parse(storedSocio) as Socio;
          setSocio(socioParsed);
        } catch (error) {
          console.error("Error al cargar el socio:", error);
        }
      }
    };

    cargarSocio();
  }, [propSocio]);

  return (
    <div className="min-h-screen bg-gray-100">
      {loading && <Loading />}
      <div className="container mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-3xl sm:text-4xl font-bold text-left">
            Bienvenido, {socio?.socio_nombre}
          </h1>
          {isAdmin && (
            <button
              onClick={() => navigate("/admin")}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg inline-flex items-center transition-colors duration-200"
            >
              <Search className="w-5 h-5 mr-2" />
              Buscar otro socio
            </button>
          )}
        </div>

        <p className="text-gray-600 mb-6 text-left">Gestiona tu estado de socio</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <Card
            icon={CreditCard}
            title="Mis cuotas pagas"
            description="Revisa el estado de tus cuotas y pagos pendientes."
            link="/cuotas"
            navigate={navigate}
          />
          <Card
            icon={Bell}
            title="Notificaciones"
            description="Revisa tus notificaciones y mensajes importantes."
            link="/notificaciones"
            navigate={navigate}
          />
          <Card
            icon={Calendar}
            title="Mis eventos"
            description="Explora los eventos disponibles para ti como socio."
            link="/eventos"
            navigate={navigate}
          />
          <Card
            icon={Shield}
            title="Mi caja de seguridad"
            description="Accede a tu caja de seguridad personal."
            link="/contratos"
            navigate={navigate}
          />
          <Card
            icon={Settings}
            title="Perfil"
            description="Ajusta las preferencias de tu cuenta."
            link="/perfil"
            navigate={navigate}
          />
          <Card
            icon={Gift}
            title="Beneficios"
            description="Obtén descuentos y cupones de envío gratis."
            link="/beneficios"
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
}
