import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-white shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] py-4">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <p className="text-sm text-gray-600">&copy; 2024 Intercam. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  )
}