import React, { useEffect, useState } from 'react';
import { ChevronRight, Home } from 'lucide-react';
import { Link } from 'react-router-dom';
import ContratosService from '../services/contratosCofresService.tsx';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading.tsx'

type Movimiento = {
  MovimientoCuentaCorrienteCofre_comprobanteRelacionado: string;
  MovimientoCuentaCorrienteCofre_comprobanteTipo: number;
  MovimientoCuentaCorrienteCofre_fechaIngreso: number;
  MovimientoCuentaCorrienteCofre_importe: number;
  MovimientoCuentaCorrienteCofre_importeCobrar: number;
  MovimientoCuentaCorrienteCofre_procesado: boolean;
}

export default function MovimientosCuentaCorrienteTable() {
  const [movimientos, setMovimientos] = useState<Movimiento[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedSocio = localStorage.getItem('socioSeleccionado');
        
        if (!storedSocio) {
          throw new Error('Socio no encontrado en el localStorage');
        }

        const socio = JSON.parse(storedSocio);
        const id = socio.socio_id;

        if (!id) {
          throw new Error('ID del socio no encontrado');
        }

        const data = await ContratosService.getContratosSocios(id, getAccessTokenSilently);
        setMovimientos(data);
      } catch (error: any) {
        setError(error.message);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <nav className="flex mb-6" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link to="/inicio" className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-800">
                <Home className="w-4 h-4 mr-2" />
                Inicio
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRight className="w-4 h-4 text-gray-400" />
                <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Mi caja de seguridad</span>
              </div>
            </li>
          </ol>
        </nav>

        <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-800">Mi caja de seguridad</h1>

        {error && <p className="text-red-600 mb-4">{error}</p>}

        <div className="overflow-x-auto bg-white shadow-md rounded-lg">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Comprobante Relacionado</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">Comprobante Tipo</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha Ingreso</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Importe</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Importe a cobrar</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {movimientos.map((movimiento, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-2 whitespace-nowrap hidden sm:table-cell">{movimiento.MovimientoCuentaCorrienteCofre_comprobanteRelacionado}</td>
                  <td className="px-4 py-2 whitespace-nowrap hidden md:table-cell">{movimiento.MovimientoCuentaCorrienteCofre_comprobanteTipo}</td>
                  <td className="px-4 py-2 whitespace-nowrap">{movimiento.MovimientoCuentaCorrienteCofre_fechaIngreso}</td>
                  <td className="px-4 py-2 whitespace-nowrap">${movimiento.MovimientoCuentaCorrienteCofre_importe.toFixed(2)}</td>
                  <td className="px-4 py-2 whitespace-nowrap">${movimiento.MovimientoCuentaCorrienteCofre_importeCobrar.toFixed(2)}</td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <span
                      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        movimiento.MovimientoCuentaCorrienteCofre_procesado
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      }`}
                    >
                      {movimiento.MovimientoCuentaCorrienteCofre_procesado ? 'Pagado' : 'No pagado'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {movimientos.length === 0 && !error && (
          <div className="flex justify-center items-center py-12">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}