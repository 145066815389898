import React, { useEffect, useState } from "react";
import { Bell, ChevronRight, Home, X } from "lucide-react";
import { Link } from "react-router-dom";
import { getNotificaciones, markNotificacionAsRead, deleteNotificacion } from "../services/notificacionesService.tsx";
import { useAuth0 } from "@auth0/auth0-react";

type Notification = {
    notificacion_id: number;
    socio_id: number;
    cliente_cofre_id?: number | null;
    tipo: string;
    mensaje: string;
    fecha: string;
    leida: boolean;
    deleted: boolean; // Asegúrate de que este campo está incluido en la API
};

export default function NotificationsList({ onBack }: { onBack: () => void }) {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [loading, setLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true);
            try {
                const storedSocio = localStorage.getItem("socioSeleccionado");
                if (!storedSocio) {
                    setNotifications([]);
                    setLoading(false);
                    return;
                }

                const socio = JSON.parse(storedSocio);
                const id = socio.socio_id;

                if (!id) {
                    setNotifications([]);
                    setLoading(false);
                    return;
                }

                const notificaciones = await getNotificaciones(id, getAccessTokenSilently);

                if (notificaciones && notificaciones.length > 0) {
                    // Filtrar notificaciones no eliminadas
                    setNotifications(notificaciones.filter(notif => !notif.deleted));
                } else {
                    setNotifications([]);
                }
            } catch (error) {
                setNotifications([]);
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, [getAccessTokenSilently]);

    const markAsRead = async (id: number) => {
        try {
            const success = await markNotificacionAsRead(id, getAccessTokenSilently);
            if (success) {
                setNotifications(notifications.map(notif =>
                    notif.notificacion_id === id ? { ...notif, leida: true } : notif
                ));
            }
        } catch (error) {
            console.error("Error al marcar como leída:", error);
        }
    };

    const deleteNotification = async (id: number) => {
        try {
            const success = await deleteNotificacion(id, getAccessTokenSilently);
            if (success) {
                setNotifications(notifications.filter(notif => notif.notificacion_id !== id));
            }
        } catch (error) {
            console.error("Error al eliminar notificación:", error);
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800 text-left">
            <div className="container mx-auto px-4 py-8">
                <nav className="flex mb-6" aria-label="Breadcrumb">
                    <ol className="inline-flex items-left space-x-1 md:space-x-3">
                        <li className="inline-flex">
                            <Link to="/inicio" className="inline-flex text-sm font-medium text-blue-600 hover:text-blue-800">
                                <Home className="w-4 h-4 mr-2" />
                                Inicio
                            </Link>
                        </li>
                        <li>
                            <div className="flex items-left">
                                <ChevronRight className="w-4 h-4 text-gray-400" />
                                <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Notificaciones</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <h1 className="text-3xl font-bold mb-6 text-gray-800">Notificaciones</h1>

                {notifications.length === 0 ? (
                    <div className="bg-white rounded-lg p-4 shadow-md text-center">
                        <p className="text-gray-500">No tienes notificaciones para mostrar</p>
                    </div>
                ) : (
                    <div className="space-y-4">
                        {notifications.map(notification => (
                            <div
                                key={notification.notificacion_id}
                                className={`bg-white rounded-lg p-4 shadow-md flex items-start justify-between ${notification.leida ? 'opacity-70' : ''}`}
                            >
                                <div className="flex items-start space-x-4">
                                    <Bell className={`w-6 h-6 mt-1 ${notification.leida ? 'text-gray-400' : 'text-blue-500'}`} />
                                    <div>
                                        <h2 className="text-lg font-semibold text-gray-800">{notification.tipo}</h2>
                                        <p className="text-gray-600 mt-1">{notification.mensaje}</p>
                                        <p className="text-sm text-gray-500 mt-2">{notification.fecha}</p>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    {!notification.leida && (
                                        <button
                                            onClick={() => markAsRead(notification.notificacion_id)}
                                            className="text-blue-500 hover:text-blue-700 transition-colors"
                                            aria-label="Marcar como leída"
                                        >
                                            Marcar como leída
                                        </button>
                                    )}
                                    <button
                                        onClick={() => deleteNotification(notification.notificacion_id)}
                                        className="text-red-500 hover:text-red-700 transition-colors"
                                        aria-label="Eliminar notificación"
                                    >
                                        <X className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
