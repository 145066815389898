import React,{ createContext, useContext, useState, ReactNode } from "react";
import { Socio } from "../types/Socio";

// Definir la interfaz Socio

// Definir el tipo del contexto
interface SocioContextType {
  socio: Socio | null;
  setSocio: (Socio: Socio) => void;
}

// Crear el contexto
const SocioContext = createContext<SocioContextType | undefined>(undefined);

// Proveedor del contexto
export const SocioProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [socio, setSocio] = useState<Socio | null>(null);

  return (
    <SocioContext.Provider value={{ socio, setSocio }}>
      {children}
    </SocioContext.Provider>
  );
};

// Custom hook para utilizar el contexto
export const useSocioContext = () => {
  const context = useContext(SocioContext);
  if (!context) {
    throw new Error("useSocioContext debe usarse dentro de un SocioProvider");
  }
  return context;
};
