import React, { useState, useEffect } from 'react';
import { Bell, ChevronRight, Home, X } from 'lucide-react';
import { Link } from 'react-router-dom';

type Notification = {
  id: number;
  title: string;
  message: string;
  date: string;
  read: boolean;
};

const initialNotifications: Notification[] = [
  { id: 1, title: 'Pago recibido', message: 'Tu pago de la cuota de Marzo ha sido procesado exitosamente.', date: '2024-03-15', read: false },
  { id: 2, title: 'Recordatorio de pago', message: 'La cuota de Abril vence en 5 días. No olvides realizar el pago.', date: '2024-04-10', read: false },
  { id: 3, title: 'Nuevo servicio disponible', message: 'Hemos añadido un nuevo servicio de asesoría legal para nuestros socios.', date: '2024-04-01', read: true },
  { id: 4, title: 'Actualización de términos', message: 'Hemos actualizado nuestros términos y condiciones. Por favor, revísalos.', date: '2024-03-20', read: true },
  { id: 5, title: 'Mantenimiento programado', message: 'El sistema estará en mantenimiento el próximo domingo de 2am a 4am.', date: '2024-04-05', read: false },
];

export default function NotificationsList({ onBack }: { onBack: () => void }) {
  const [notifications, setNotifications] = useState<Notification[]>(() => {
    // Intentar cargar las notificaciones desde el localStorage
    const storedNotifications = localStorage.getItem('notifications');
    return storedNotifications ? JSON.parse(storedNotifications) : initialNotifications;
  });

  // Efecto para almacenar las notificaciones en el localStorage cada vez que cambian
  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
  }, [notifications]);

  const markAsRead = (id: number) => {
    setNotifications(notifications.map(notif =>
      notif.id === id ? { ...notif, read: true } : notif
    ));
  };

  const deleteNotification = (id: number) => {
    setNotifications(notifications.filter(notif => notif.id !== id));
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-800 text-left">
      <div className="container mx-auto px-4 py-8">
        <nav className="flex mb-6" aria-label="Breadcrumb">
          <ol className="inline-flex items-left space-x-1 md:space-x-3">
            <li className="inline-flex">
              <Link to="/inicio" className="inline-flex text-sm font-medium text-blue-600 hover:text-blue-800">
                <Home className="w-4 h-4 mr-2" />
                Inicio
              </Link>
            </li>
            <li>
              <div className="flex items-left">
                <ChevronRight className="w-4 h-4 text-gray-400" />
                <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Notificaciones</span>
              </div>
            </li>
          </ol>
        </nav>

        <h1 className="text-3xl font-bold mb-6 text-gray-800">Notificaciones</h1>

        <div className="space-y-4">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`bg-white rounded-lg p-4 shadow-md flex items-start justify-between ${
                notification.read ? 'opacity-70' : ''
              }`}
            >
              <div className="flex items-start space-x-4">
                <Bell className={`w-6 h-6 mt-1 ${notification.read ? 'text-gray-400' : 'text-blue-500'}`} />
                <div>
                  <h2 className="text-lg font-semibold text-gray-800">{notification.title}</h2>
                  <p className="text-gray-600 mt-1">{notification.message}</p>
                  <p className="text-sm text-gray-500 mt-2">{notification.date}</p>
                </div>
              </div>
              <div className="flex space-x-2">
                {!notification.read && (
                  <button
                    onClick={() => markAsRead(notification.id)}
                    className="text-blue-500 hover:text-blue-700 transition-colors"
                    aria-label="Marcar como leída"
                  >
                    Marcar como leída
                  </button>
                )}
                <button
                  onClick={() => deleteNotification(notification.id)}
                  className="text-red-500 hover:text-red-700 transition-colors"
                  aria-label="Eliminar notificación"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
