import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/App.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import { SocioProvider } from "./context/socioContext.tsx";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider} from './context/AuthProvider.tsx';
 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      scope: "openid profile email",
    }}
  >   
    <AuthProvider>
      <SocioProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </SocioProvider>
    </AuthProvider>
</Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
