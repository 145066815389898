"use client"

import { useState } from "react"
import React from "react"
import { ChevronRight, Home, Package } from "lucide-react"
import { Link } from "react-router-dom"

const enviosTipos = [
  {
    tipo: "Grande",
    dimensiones: "1m x 1m",
    descripcion: "Ideal para artículos voluminosos",
    icon: "text-red-500",
    maxUsos: 3
  },
  {
    tipo: "Mediano",
    dimensiones: "50cm x 50cm",
    descripcion: "Perfecto para paquetes medianos",
    icon: "text-yellow-500",
    maxUsos: 3
  },
  {
    tipo: "Chico",
    dimensiones: "Bolsa para camisa/pantalón o remera",
    descripcion: "Adecuado para prendas individuales",
    icon: "text-green-500",
    maxUsos: 3
  }
]

type ToastType = {
  title: string;
  description: string;
  variant?: "default" | "destructive";
} | null;

const Toast = ({ title, description, variant = "default" }) => (
  <div className={`fixed bottom-4 right-4 p-4 rounded-md shadow-lg ${variant === "destructive" ? "bg-red-500" : "bg-green-500"} text-white`}>
    <h3 className="font-bold">{title}</h3>
    <p>{description}</p>
  </div>
)

export default function Beneficios() {
  const [usosRestantes, setUsosRestantes] = useState(enviosTipos.map(() => 3))
  const [toast, setToast] = useState<ToastType>(null)

  const showToast = (title: string, description: string, variant: "default" | "destructive" = "default") => {
    setToast({ title, description, variant })
    setTimeout(() => setToast(null), 3000)
  }

  // Función para usar crédito
  const usarCredito = (index: number) => {
    if (usosRestantes[index] > 0) {
      setUsosRestantes(prev => {
        const newUsos = [...prev]
        newUsos[index] -= 1
        return newUsos
      })
      showToast("Crédito utilizado", `Has usado un crédito de envío gratis para un paquete ${enviosTipos[index].tipo.toLowerCase()}.`)
    } else {
      showToast("Crédito agotado", "Ya has usado todos los créditos disponibles para este tipo de envío.", "destructive")
    }
  }

  return (
    <div className="container mx-auto p-4">
      <nav className="flex mb-6" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link to="/inicio" className="inline-flex text-sm font-medium text-blue-600 hover:text-blue-800">
              <Home className="w-4 h-4 mr-2" />
              Inicio
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRight className="w-4 h-4 text-gray-400" />
              <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Beneficios</span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-3xl font-bold text-center mb-12">Cupones de Envío Gratis</h1>
      </div>
      <div className="rounded-lg overflow-hidden mb-8">
        <div className="px-6 pb-6 flex flex-col items-center justify-center space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
            {enviosTipos.map((envio, index) => (
              <div
                key={index}
                className={`bg-white rounded-lg shadow-md overflow-hidden ${
                  usosRestantes[index] === 0 ? "opacity-50" : ""
                } transition-all duration-300 ease-in-out transform hover:scale-105`}
              >
                <div className="flex flex-col items-center justify-center p-6">
                  <div className="mb-8 w-full">
                    <div className="flex flex-col items-center justify-center space-y-4">
                      <div className="relative w-64 h-32 bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg shadow-lg overflow-hidden flex flex-col items-center justify-center">
                        <Package className={`w-12 h-12 mb-2 ${envio.icon}`} />
                        <span className="text-2xl font-bold text-white">{usosRestantes[index]} Envíos {envio.tipo}s</span>
                        <span className="text-xs font-bold text-white">{envio.dimensiones}</span>
                        <div className="absolute top-0 right-0 w-16 h-16 bg-yellow-400 transform rotate-45 translate-x-8 -translate-y-8"></div>
                      </div>
                      <span className="text-xs font-bold">{envio.descripcion}</span>
                    </div>
                  </div>
                  <button
                    onClick={() => usarCredito(index)}
                    disabled={usosRestantes[index] === 0}
                    className={`text-sm py-1 px-4 border ${
                      usosRestantes[index] === 0
                        ? "border-gray-300 text-gray-300 cursor-not-allowed"
                        : "border-black text-black hover:bg-black hover:text-white"
                    } transition-colors duration-300`}
                  >
                    {usosRestantes[index] === 0 ? "Usado" : "Usar Crédito"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {toast && <Toast {...toast} />}
    </div>
  )
}