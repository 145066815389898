import React from 'react';
 
const SkeletonLoader = () => {
  return (
    <div className="space-y-6 mt-6">
        {[...Array(3)].map((_, index) => (
            <div
            key={index}
            className="w-full h-17 pl-12 pr-6 py-2 border rounded-lg text-lg bg-white shadow-md flex flex-col animate-pulse"
            >
              <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
              <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
              <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            </div>
    ))}
    </div>
  );
};

export default SkeletonLoader;
