import React from 'react'
import { AlertCircle, PhoneCall } from 'lucide-react'
export default function SocioStatus() {
    return (
    <div className="max-w-4xl mx-auto p-6 mt-24 mb-0 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-center">Estado de Socio</h1>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
          <div className="flex items-center">
            <AlertCircle className="w-6 h-6 mr-2" />
            <p className="font-bold">Usted no está registrado como socio.</p>
          </div>
          <p className="mt-2">Para registrarse y disfrutar de los beneficios, por favor comuníquese con la Cámara Comercial.</p>
        </div>
      <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
        <div className="flex items-center">
          <PhoneCall className="w-6 h-6 mr-2" />
          <p className="font-bold">Contacto de la Cámara Comercial</p>
        </div>
        <p className="mt-2">Teléfono: (2314) 56-7890</p>
        <p>Email: info@camaracomercial.com</p>
        <p>Horario de atención: Lunes a Viernes de 9:00 a 15:00</p>
      </div>
    </div>)
}