import { useEffect } from 'react';

function Titulo({ texto }) {
  useEffect(() => {
    document.title = texto;
  }, [texto]);

  return null
}

export default Titulo;