import React, { useState, useEffect } from 'react';
import { Socio } from '../../types/Socio';

interface SearchResultsProps {
  listaSocios: Socio[] | null;
  handleSelectSocio: (id: string) => Promise<void>;
}

const SearchResults: React.FC<SearchResultsProps> = ({ listaSocios, handleSelectSocio }) => {
  const [showAll, setShowAll] = useState(false);
  const sociosToShow = listaSocios ? (showAll ? listaSocios : listaSocios.slice(0, 5)) : [];

  useEffect(() => {
    // Cuando la lista de socios cambia, reseteamos el estado showAll
    setShowAll(false);
  }, [listaSocios]);

  return (
    <div className="bg-white rounded-lg shadow w-full text-left">
      <div className="grid grid-cols-1 divide-y">
        {sociosToShow.map((s) => (
          <div
            key={s.socio_id}
            className="p-4 hover:bg-gray-50 cursor-pointer transition-colors flex items-start justify-between"
            onClick={async () => handleSelectSocio(s.socio_id)}
          >
            <div className="flex flex-col items-start">
              <h3 className="font-semibold text-lg">{s.socio_nombre}</h3>
              <p className="text-gray-600">{s.socio_mail}</p>
              <p className="text-sm text-gray-500">DNI: {s.socio_dni}</p>
            </div>
            <button className="px-4 py-2 text-blue-500 hover:text-blue-700 transition-colors">
              Ver detalles &rarr;
            </button>
          </div>
        ))}
      </div>
      {listaSocios && listaSocios.length > 5 && !showAll && (
        <div className="text-center mt-6 pt-6 mb-6 border-t border-b border-dashed border-blue-400">
        <button
          className="text-blue-600 hover:text-blue-800 font-semibold transition-colors duration-200 ease-in-out"
          onClick={() => setShowAll(true)}
        >
          Más resultados &darr;
        </button>
      </div>
      
      )}
    </div>
  );
};

export { SearchResults };
