import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { User } from "lucide-react";
import socioService from "../services/socioService.tsx";

interface UserOption {
  socio_mail: string;
  socio_dni: string;
  socio_cuit: string;
  socio_domicilio: string;
  socio_id: string;
  socio_nombre: string;
  socio_tipoEmpresa: string;
  socio_campanias: string;
  socio_acta: number;
  socio_celular: string;
  socio_condicionFiscal: number;
  socio_enviarMail: boolean;
  socio_estado: number;
  socio_fechaAprobacion: string;
  socio_fechaNacimiento: string;
  socio_firma: string;
  socio_gestion: string;
  socio_habilitacion: string;
  socio_localidad: number;
  socio_modificado: string;
  socio_motivoBaja: string;
  socio_nacionalidad: string;
  socio_numero: number;
  socio_otroTelefono: string;
  socio_padrino1: string;
  socio_padrino2: string;
  socio_rubro: number;
  socio_sector: number;
  socio_segmento: string;
  socio_tarjetaEntregada: boolean;
  socio_tarjetaFechaEntrega: null;
  socio_telefono: string;
  socio_tieneCajaSeguridad: boolean;
  socio_tipoSocio: string;
}

const Button = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => (
  <button
    className={`inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-auto px-4 py-3 ${className}`}
    ref={ref}
    {...props}
  />
));
Button.displayName = "Button";

const Card = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={`rounded-lg border bg-card text-card-foreground shadow-sm ${className}`}
    {...props}
  />
);

const CardHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={`flex flex-col space-y-1.5 p-6 ${className}`} {...props} />
);

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={`text-xl sm:text-2xl lg:text-3xl font-semibold leading-none tracking-tight ${className}`}
    {...props}
  ></h3>
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={`text-sm lg:text-base text-muted-foreground ${className}`}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={`p-6 pt-0 ${className}`} {...props} />
);

export default function UserSelection() {
  const [socios, setSocios] = useState<UserOption[]>([]);
  const [redirectToInicio, setRedirectToInicio] = useState(false);

  useEffect(() => {
    const storedSocios = socioService.getSociosFromLocalStorage();
    const storedSocio = socioService.getSelectedSocioFromLocalStorage();

    if (storedSocio) {
      setRedirectToInicio(true);
    }

    if (storedSocios) {
      setSocios(storedSocios);

      // if (storedSocios.length === 1) {
      //   socioService.saveSelectedSocioInLocalStorage(storedSocios[0]);
      //   setRedirectToInicio(true);
      // }
    }
  }, []);

  const handleUserSelect = (socio: UserOption) => {
    socioService.clearSelectedSocioFromLocalStorage();
    socioService.saveSelectedSocioInLocalStorage(socio);
    setRedirectToInicio(true);
  };

  if (redirectToInicio) {
    return <Navigate to="/inicio" />;
  }

  return (
    <div className="h-auto lg:py-32 bg-gray-100 flex items-center justify-center p-4">
      <Card className="w-full bg-white max-w-md mx-auto lg:max-w-4xl xl:max-w-6xl">
        <CardHeader className="lg:py-8">
          <CardTitle className="text-center">Seleccionar Socio</CardTitle>
          <CardDescription className="text-center">
            Elige un socio para continuar
          </CardDescription>
        </CardHeader>
        <CardContent className="grid gap-4 sm:gap-6 lg:gap-8">
          {socios.map((socio) => (
            <Button
              key={socio.socio_id}
              className="w-full justify-start bg-gray-100 text-left h-auto py-4 px-4 sm:px-6 lg:py-6 lg:px-8"
              onClick={() => handleUserSelect(socio)}
            >
              <div className="flex items-center w-full">
                <div className="bg-gray-200 rounded-full p-2 mr-4 flex-shrink-0 lg:p-3 lg:mr-6">
                  <User className="h-5 w-5 sm:h-6 sm:w-6 lg:h-8 lg:w-8 text-gray-500" />
                </div>
                <div className="flex-grow min-w-0">
                  <div className="font-semibold truncate text-base sm:text-lg lg:text-xl">
                    {socio.socio_nombre}
                  </div>
                  <div className="text-sm lg:text-base text-gray-500 truncate">
                    {socio.socio_dni}
                  </div>
                </div>
              </div>
            </Button>
          ))}
        </CardContent>
      </Card>
    </div>
  );
}
