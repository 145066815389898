import axios from "axios";

interface UserData {
  socio_mail: string;
  socio_dni: string;
  socio_cuit: string;
  socio_domicilio: string;
  socio_id: string;
  socio_nombre: string;
  socio_tipoEmpresa: string;
  socio_campanias: string;
  socio_acta: number;
  socio_celular: string;
  socio_condicionFiscal: number;
  socio_enviarMail: boolean;
  socio_estado: number;
  socio_fechaAprobacion: string;
  socio_fechaNacimiento: string;
  socio_firma: string;
  socio_gestion: string;
  socio_habilitacion: string;
  socio_localidad: number;
  socio_modificado: string;
  socio_motivoBaja: string;
  socio_nacionalidad: string;
  socio_numero: number;
  socio_otroTelefono: string;
  socio_padrino1: string;
  socio_padrino2: string;
  socio_rubro: number;
  socio_sector: number;
  socio_segmento: string;
  socio_tarjetaEntregada: boolean;
  socio_tarjetaFechaEntrega: null;
  socio_telefono: string;
  socio_tieneCajaSeguridad: boolean;
  socio_tipoSocio: string;
}

const getUserProfile = async (
  email: string,
  getAccessTokenSilently: () => Promise<string>
): Promise<UserData[]> => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get<UserData[]>(
      `${process.env.REACT_APP_BACK_URL}/socio/email/${email}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener el perfil de usuario:", error);
    throw error;
  }
};

const saveSociosInLocalStorage = (socios: UserData[]) => {
  localStorage.setItem("socios", JSON.stringify(socios));
};

const getSociosFromLocalStorage = (): UserData[] | null => {
  const socios = localStorage.getItem("socios");
  return socios ? JSON.parse(socios) : null;
};

const saveSelectedSocioInLocalStorage = (socio: UserData) => {
  localStorage.setItem("socioSeleccionado", JSON.stringify(socio));
};

const getSelectedSocioFromLocalStorage = (): UserData | null => {
  const socio = localStorage.getItem("socioSeleccionado");
  return socio ? JSON.parse(socio) : null;
};
const clearSelectedSocioFromLocalStorage = () => {
  localStorage.removeItem("socioSeleccionado");
};

const clearLocalStorage = () => {
  localStorage.removeItem("socios");
  localStorage.removeItem("socioSeleccionado");
};

const autoSelectSingleSocio = () => {
  const socios = getSociosFromLocalStorage();
  if (socios && socios.length === 1) {
    saveSelectedSocioInLocalStorage(socios[0]);
    return socios[0];
  }
  return null;
};

const checkAndRedirectIfSocioSelected = (): boolean => {
  return !!getSelectedSocioFromLocalStorage();
};

const socioService = {
  getUserProfile,
  saveSociosInLocalStorage,
  getSociosFromLocalStorage,
  saveSelectedSocioInLocalStorage,
  getSelectedSocioFromLocalStorage,
  clearSelectedSocioFromLocalStorage,
  clearLocalStorage,
  autoSelectSingleSocio,
  checkAndRedirectIfSocioSelected,
};

export default socioService;
