import React, { useEffect, useState } from 'react';
import { ChevronRight, Home, ChevronLeft, ChevronRightIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import ContratosService from '../services/contratosCofresService.tsx';
import Loading from './Loading.tsx'
import { useAuth } from '../context/AuthProvider.tsx';

type Movimiento = {
  MovimientoCuentaCorrienteCofre_comprobanteRelacionado: string;
  MovimientoCuentaCorrienteCofre_comprobanteTipo: number;
  MovimientoCuentaCorrienteCofre_fechaIngreso: number;
  MovimientoCuentaCorrienteCofre_importe: number;
  MovimientoCuentaCorrienteCofre_importeCobrar: number;
  MovimientoCuentaCorrienteCofre_procesado: boolean;
}

const ITEMS_PER_PAGE = 13;

export default function MovimientosCuentaCorrienteTable() {
  const [movimientos, setMovimientos] = useState<Movimiento[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { accessToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const totalPages = Math.ceil(movimientos.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentMovimientos = movimientos.slice(startIndex, endIndex);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storedSocio = localStorage.getItem('socioSeleccionado');
        
        if (!storedSocio) {
          throw new Error('Socio no encontrado en el localStorage');
        }

        const socio = JSON.parse(storedSocio);
        const id = socio.socio_id;

        if (!id) {
          throw new Error('ID del socio no encontrado');
        }

        const data = await ContratosService.getContratosSocios(id, accessToken);
        setMovimientos(data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    return (
      <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div className="flex justify-between flex-1 sm:hidden">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Anterior
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Siguiente
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Mostrando <span className="font-medium">{startIndex + 1}</span> a{' '}
              <span className="font-medium">
                {Math.min(endIndex, movimientos.length)}
              </span>{' '}
              de <span className="font-medium">{movimientos.length}</span> resultados
            </p>
          </div>
          <div>
            <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-l-md border border-gray-300 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Anterior</span>
                <ChevronLeft className="h-5 w-5" />
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                    currentPage === index + 1
                      ? 'z-10 bg-blue-600 border-blue-600 text-white'
                      : 'text-gray-700 hover:bg-gray-50 border-gray-300 bg-white'
                  } border`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-md border border-gray-300 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Siguiente</span>
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <nav className="flex mb-6" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link to="/inicio" className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-800">
                <Home className="w-4 h-4 mr-2" />
                Inicio
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRight className="w-4 h-4 text-gray-400" />
                <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Mi caja de seguridad</span>
              </div>
            </li>
          </ol>
        </nav>

        <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-800">Mi caja de seguridad</h1>

        {error && <p className="text-red-600 mb-4">{error}</p>}

        <div className="overflow-x-auto bg-white shadow-md rounded-lg">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell">Comprobante Relacionado</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell">Comprobante Tipo</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha Ingreso</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Importe</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Importe a cobrar</th>
                <th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentMovimientos.map((movimiento, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-2 whitespace-nowrap hidden sm:table-cell">{movimiento.MovimientoCuentaCorrienteCofre_comprobanteRelacionado}</td>
                  <td className="px-4 py-2 whitespace-nowrap hidden md:table-cell">{movimiento.MovimientoCuentaCorrienteCofre_comprobanteTipo}</td>
                  <td className="px-4 py-2 whitespace-nowrap">{movimiento.MovimientoCuentaCorrienteCofre_fechaIngreso}</td>
                  <td className="px-4 py-2 whitespace-nowrap">${movimiento.MovimientoCuentaCorrienteCofre_importe.toFixed(2)}</td>
                  <td className="px-4 py-2 whitespace-nowrap">${movimiento.MovimientoCuentaCorrienteCofre_importeCobrar.toFixed(2)}</td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <span
                      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        movimiento.MovimientoCuentaCorrienteCofre_procesado
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      }`}
                    >
                      {movimiento.MovimientoCuentaCorrienteCofre_procesado ? 'Pagado' : 'No pagado'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {renderPagination()}
        </div>

        {loading && (
          <div className="flex justify-center items-center py-12">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}