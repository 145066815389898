import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  User, Mail, CreditCard, MapPin, Briefcase, Hash, Building, Home, 
  ChevronRight, Phone, Calendar, Flag, FileText, Users, Layers, 
  Tag, CheckSquare
} from 'lucide-react';
import { Socio } from '../types/Socio';

const initialSocio: Socio = {
  socio_mail: '',
  socio_dni: '',
  socio_cuit: '',
  socio_domicilio: '',
  socio_id: '',
  socio_nombre: '',
  socio_tipoEmpresa: '',
  socio_campanias: '',
  socio_acta: 0,
  socio_celular: '',
  socio_condicionFiscal: 0,
  socio_enviarMail: false,
  socio_estado: 0,
  socio_fechaAprobacion: '',
  socio_fechaNacimiento: '',
  socio_firma: '',
  socio_gestion: '',
  socio_habilitacion: '',
  socio_localidad: 0,
  socio_modificado: '',
  socio_motivoBaja: '',
  socio_nacionalidad: '',
  socio_numero: 0,
  socio_otroTelefono: '',
  socio_padrino1: '',
  socio_padrino2: '',
  socio_rubro: 0,
  socio_sector: 0,
  socio_segmento: '',
  socio_tarjetaEntregada: false,
  socio_tarjetaFechaEntrega: null,
  socio_telefono: '',
  socio_tieneCajaSeguridad: false,
  socio_tipoSocio: '',
};

const iconMap = {
  socio_nombre: User,
  socio_mail: Mail,
  socio_dni: CreditCard,
  socio_cuit: Hash,
  socio_domicilio: MapPin,
  socio_id: Briefcase,
  socio_tipoEmpresa: Building,
  socio_campanias: FileText,
  socio_acta: FileText,
  socio_celular: Phone,
  socio_condicionFiscal: FileText,
  socio_enviarMail: Mail,
  socio_estado: FileText,
  socio_fechaAprobacion: Calendar,
  socio_fechaNacimiento: Calendar,
  socio_firma: FileText,
  socio_gestion: FileText,
  socio_habilitacion: FileText,
  socio_localidad: MapPin,
  socio_modificado: Calendar,
  socio_motivoBaja: FileText,
  socio_nacionalidad: Flag,
  socio_numero: Hash,
  socio_otroTelefono: Phone,
  socio_padrino1: Users,
  socio_padrino2: Users,
  socio_rubro: Layers,
  socio_sector: Layers,
  socio_segmento: Tag,
  socio_tarjetaEntregada: CreditCard,
  socio_tarjetaFechaEntrega: Calendar,
  socio_telefono: Phone,
  socio_tieneCajaSeguridad: CheckSquare,
  socio_tipoSocio: User,
};

const labelMap: { [key: string]: string } = {
  socio_nombre: 'Nombre',
  socio_mail: 'Email',
  socio_dni: 'DNI',
  socio_cuit: 'CUIT',
  socio_domicilio: 'Domicilio',
  socio_id: 'Socio ID',
  socio_tipoEmpresa: 'Tipo de Empresa',
  socio_campanias: 'Campañas',
  socio_acta: 'Acta',
  socio_celular: 'Celular',
  socio_condicionFiscal: 'Condición Fiscal',
  socio_enviarMail: 'Enviar Mail',
  socio_estado: 'Estado',
  socio_fechaAprobacion: 'Fecha de Aprobación',
  socio_fechaNacimiento: 'Fecha de Nacimiento',
  socio_firma: 'Firma',
  socio_gestion: 'Gestión',
  socio_habilitacion: 'Habilitación',
  socio_localidad: 'Localidad',
  socio_modificado: 'Modificado',
  socio_motivoBaja: 'Motivo de Baja',
  socio_nacionalidad: 'Nacionalidad',
  socio_numero: 'Número',
  socio_otroTelefono: 'Otro Teléfono',
  socio_padrino1: 'Padrino 1',
  socio_padrino2: 'Padrino 2',
  socio_rubro: 'Rubro',
  socio_sector: 'Sector',
  socio_segmento: 'Segmento',
  socio_tarjetaEntregada: 'Tarjeta Entregada',
  socio_tarjetaFechaEntrega: 'Fecha Entrega Tarjeta',
  socio_telefono: 'Teléfono',
  socio_tieneCajaSeguridad: 'Tiene Caja Seguridad',
  socio_tipoSocio: 'Tipo de Socio',
};

const ProfileField: React.FC<{ label: string; value: any; icon: React.ElementType }> = ({ label, value, icon: Icon }) => (
  <li className="flex items-center py-3 border-b border-gray-200 last:border-b-0">
    <Icon className="w-5 h-5 text-gray-500 mr-3 flex-shrink-0" />
    <span className="font-medium mr-2 flex-shrink-0">{label}:</span>
    <span className="text-gray-700 truncate">
      {typeof value === 'boolean' ? (value ? 'Sí' : 'No') : value || 'N/A'}
    </span>
  </li>
);

export default function UserProfile() {
  const [Socio, setSocio] = useState<Socio>(initialSocio);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = () => {
      try {
        setError(null);
        const cachedSocio = localStorage.getItem('socioSeleccionado');

        if (cachedSocio) {
          const socioData: Socio = JSON.parse(cachedSocio);
          setSocio(socioData);
        } else {
          setError('No se encontró un socio seleccionado en el localStorage.');
          navigate('/seleccionar-socio');
        }
      } catch (error) {
        setError('Error al cargar los datos del usuario');
        console.error('Error al obtener el perfil de usuario:', error);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const SocioEntries = Object.entries(Socio);
  const midpoint = Math.ceil(SocioEntries.length / 2);

  return (
    <div className="container mx-auto p-6">
      <nav className="flex mb-6" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <Link to="/inicio" className="inline-flex text-sm font-medium text-blue-600 hover:text-blue-800">
              <Home className="w-4 h-4 mr-2" />
              Inicio
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRight className="w-4 h-4 text-gray-400" />
              <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Perfil</span>
            </div>
          </li>
        </ol>
      </nav>

      <div className="mx-auto p-6 mt-4 bg-white shadow-lg rounded-lg">
        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Perfil de Usuario</h1>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-6">
          <div className="md:w-1/2 space-y-6">
            <ul className="divide-y divide-gray-200">
              {SocioEntries.slice(0, midpoint).map(([key, value]) => (
                <ProfileField
                  key={key}
                  label={labelMap[key] || key}
                  value={value}
                  icon={iconMap[key as keyof typeof iconMap] || FileText}
                />
              ))}
            </ul>
          </div>
          <div className="md:w-1/2 space-y-6 mt-6 md:mt-0">
            <ul className="divide-y divide-gray-200">
              {SocioEntries.slice(midpoint).map(([key, value]) => (
                <ProfileField
                  key={key}
                  label={labelMap[key] || key}
                  value={value}
                  icon={iconMap[key as keyof typeof iconMap] || FileText}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}