import axios from 'axios';

const AcontecimientosService = {
  async getAcontecimientosSocios(id: number, getAccessTokenSilently: () => Promise<string>) {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_URL}/acontecimientos/`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error al obtener los acontecimientos del socio:', error);
      throw error;
    }
  }
};

export default AcontecimientosService;
