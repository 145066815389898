import React, { useEffect, useState } from 'react';
import { ChevronRight, Home } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getPagosSocios } from '../services/cuotasService.tsx';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading.tsx';

type Cuota = {
  id: number;
  mes: string;
  año: number;
  importe: number;
  fechaVencimiento: string;
  estado: 'Pagada' | 'Adeudada' | 'Por cobrar';
}
const meses = {
  'Enero': 1,
  'Febrero': 2,
  'Marzo': 3,
  'Abril': 4,
  'Mayo': 5,
  'Junio': 6,
  'Julio': 7,
  'Agosto': 8,
  'Septiembre': 9,
  'Octubre': 10,
  'Noviembre': 11,
  'Diciembre': 12,
};


export default function CuotasTable() {
  const [cuotas, setCuotas] = useState<Cuota[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedSocio = localStorage.getItem('socioSeleccionado');
        
        if (!storedSocio) {
          throw new Error('Socio no encontrado en el localStorage');
        }

        const socio = JSON.parse(storedSocio);
        const id = socio.socio_id;

        if (!id) {
          throw new Error('ID del socio no encontrado');
        }

        const cuotas = await getPagosSocios(id, getAccessTokenSilently);
        const cuotasOrdenadas = cuotas.sort((a, b) => {
          if (b.año !== a.año) {
            return b.año - a.año; // Ordenar por año de manera descendente
          } else {
            return meses[b.mes] - meses[a.mes]; // Si los años son iguales, ordenar por mes de manera descendente
          }
        }); 
        setCuotas(cuotasOrdenadas);
      } catch (error: any) {
        setError(error.message);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  const renderTableRow = (cuota: Cuota) => (
    <tr key={cuota.id} className="border-b border-gray-200 hover:bg-gray-50">
      <td className="px-4 py-3 sm:px-6 sm:py-4">
        <div className="text-gray-500 sm:hidden">{cuota.año}</div>
        <div className="font-medium text-gray-900">{cuota.mes}</div>
      </td>
      <td className="hidden px-4 py-3 sm:px-6 sm:py-4 sm:table-cell">{cuota.año}</td>
      <td className="px-4 py-3 sm:px-6 sm:py-4">${cuota.importe.toFixed(2)}</td>
      <td className="hidden px-4 py-3 sm:px-6 sm:py-4 sm:table-cell">{cuota.fechaVencimiento}</td>
      <td className="px-4 py-3 sm:px-6 sm:py-4">
        <span
          className={`px-2 py-1 rounded-full text-xs font-semibold
            ${cuota.estado === 'Pagada' ? 'bg-green-500 text-green-100' :
              cuota.estado === 'Adeudada' ? 'bg-red-500 text-red-100' :
              'bg-yellow-500 text-yellow-100'}`}
        >
          {cuota.estado}
        </span>
      </td>
    </tr>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <nav className="flex mb-6" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link to="/inicio" className="inline-flex text-sm font-medium text-blue-600 hover:text-blue-800">
                <Home className="w-4 h-4 mr-2" />
                Inicio
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRight className="w-4 h-4 text-gray-400" />
                <span className="ml-1 text-sm font-medium text-gray-600 md:ml-2">Estado de Cuotas</span>
              </div>
            </li>
          </ol>
        </nav>

        <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-left">Estado de Cuotas</h1>

        {error && <p className="text-red-600 mb-4">{error}</p>}

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="hidden sm:table-cell px-4 py-3 sm:px-6 sm:py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Año</th>
                  <th className="px-4 py-3 sm:px-6 sm:py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Periodo</th>
                  
                  <th className="px-4 py-3 sm:px-6 sm:py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Importe</th>
                  <th className="hidden sm:table-cell px-4 py-3 sm:px-6 sm:py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha de Vencimiento</th>
                  <th className="px-4 py-3 sm:px-6 sm:py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cuotas.map(renderTableRow)}
              </tbody>
            </table>
          </div>
        </div>
        {cuotas.length === 0 && !error && (
          <div className="flex justify-center items-center py-12">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}