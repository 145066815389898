import axios from 'axios';

type PagoSocio = {
  pagosSocios_id: string;
  pagosSocios_anio: number;
  pagosSocios_periodo: number;
  pagosSocios_monto: number;
  pagosSocios_fechaVencimiento: string;
  pagosSocios_estado: number;
  pagosSocios_deleted: boolean; 
};

export const getPagosSocios = async (id: number, getAccessTokenSilently: () => Promise<string>) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/pagos-socios/socio/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const filteredData = response.data
      .filter((item: PagoSocio) => !item.pagosSocios_deleted) 
      .map((item: PagoSocio) => ({
        id: item.pagosSocios_id,
        año: item.pagosSocios_anio,
        mes: getMonthFromPeriod(item.pagosSocios_periodo),
        importe: item.pagosSocios_monto,
        fechaVencimiento: formatDate(item.pagosSocios_fechaVencimiento),
        estado: getEstadoFromCodigo(item.pagosSocios_estado),
      }));

    return filteredData;
  } catch (error) {
    throw new Error(`Error al obtener datos de pagos: ${error.message}`);
  }
};

const getEstadoFromCodigo = (codigo: number) => {
  switch (codigo) {
    case 0:
      return 'Pagada';
    case 1:
      return 'Por cobrar';
    case 2:
      return 'Adeudada';
    default:
      return 'Desconocido';
  }
};

const getMonthFromPeriod = (period: number) => {
  const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  return meses[period] || 'Mes desconocido';
};

const formatDate = (fecha: string) => {
  const date = new Date(fecha);
  return date.toLocaleDateString();
};
